import { useState } from "react";
import { $enum } from "ts-enum-util";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import React from "react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import {
  CreateContentMutationVariables,
  ContentQuery,
  UpdateContentMutationVariables,
  ContentType,
  MediaType,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import { Editor } from "@tinymce/tinymce-react";
import { createSlug } from "../../utils/slug";
import ReactPlayer from "react-player";

interface Props {
  handleCreateContent?: (data: CreateContentMutationVariables) => Promise<any>;
  handleUpdateContent?: (data: UpdateContentMutationVariables) => Promise<any>;
  id?: string;
  data?: ContentQuery["content"];
  actionType: "CREATE" | "UPDATE";
  field: string;
  fieldId: string;
}

const Form: React.FC<Props> = ({
  handleCreateContent,
  handleUpdateContent,
  id,
  data,
  actionType,
  field,
  fieldId,
}) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photos, setPhotos] = useState(data?.photos || []);
  const [video, setVideo] = useState(data?.video || "");
  const [contentType, setContentType] = useState(data?.contentType || ContentType.TEXT);
  const [mediaType, setMediaType] = useState(data?.mediaType || MediaType.SINGLE);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && contentType === ContentType.TEXT) {
      return toast({
        status: "error",
        description: "Title is required",
        title: "Hata",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreateContent!({
          data: {
            contentType,
            mediaType,
            video,
            photos: {
              set: photos,
            },
            descriptions: title
              ? {
                  create: [
                    {
                      title,
                      description,
                      slug: createSlug(title),
                    },
                  ],
                }
              : undefined,
            [field]: {
              connect: {
                id: fieldId,
              },
            },
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdateContent!({
          where: {
            id: id,
          },
          data: {
            photos: {
              set: photos,
            },
            mediaType: {
              set: mediaType,
            },
            video: {
              set: video,
            },
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          İçerik {actionType === "CREATE" ? "Oluştur" : "Düzenle"}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/content/${field}/${fieldId}`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          {actionType === "CREATE" && (
            <SimpleGrid
              columns={3}
              gap={{
                base: 6,
                md: 6,
              }}
              mb={6}
            >
              {$enum(ContentType).map((cType) => {
                return (
                  <GridItem key={cType}>
                    <Button
                      width={"full"}
                      padding={5}
                      onClick={() => setContentType(cType)}
                      display="flex"
                      flexDirection={"column"}
                      justifyContent="center"
                      alignItems={"center"}
                      fontWeight="bold"
                      backgroundColor={cType === contentType ? "green.100" : "gray.200"}
                    >
                      {cType}
                    </Button>
                  </GridItem>
                );
              })}
            </SimpleGrid>
          )}
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {contentType === ContentType.IMAGE && (
              <GridItem
                colSpan={{
                  base: 2,
                  md: 2,
                }}
              >
                <Box mb={6}>
                  <FormLabel>Görsel</FormLabel>
                  <UploadDropzone
                    isSingle={false}
                    images={photos}
                    setImages={setPhotos}
                  />
                </Box>
                <FormLabel>Görsel Türü</FormLabel>
                <SimpleGrid columns={3} gap={6}>
                  {$enum(MediaType).map((mType) => {
                    return (
                      <GridItem key={mType}>
                        <Button
                          width={"full"}
                          padding={5}
                          onClick={() => setMediaType(mType)}
                          display="flex"
                          flexDirection={"column"}
                          justifyContent="center"
                          alignItems={"center"}
                          fontWeight="bold"
                          backgroundColor={mType === mediaType ? "green.100" : "gray.200"}
                        >
                          {mType}
                        </Button>
                      </GridItem>
                    );
                  })}
                </SimpleGrid>
              </GridItem>
            )}
            {contentType !== ContentType.VIDEO && actionType === "CREATE" && (
              <GridItem
                colSpan={{
                  base: 2,
                  md: 1,
                }}
              >
                <FormLabel>Başlık</FormLabel>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Başlık"
                />
              </GridItem>
            )}

            {contentType !== ContentType.VIDEO && actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Açıklama</FormLabel>
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Açıklama...",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            )}

            {contentType === ContentType.VIDEO && (
              <GridItem
                colSpan={{
                  base: 2,
                  md: 2,
                }}
              >
                <Box mb={6}>
                  <FormLabel>Video</FormLabel>
                  <Input
                    value={video}
                    onChange={(e) => setVideo(e.target.value)}
                    placeholder="Video"
                    type="text"
                  />
                </Box>
                <Box>{video && <ReactPlayer url={video} />}</Box>
              </GridItem>
            )}
          </SimpleGrid>
          <Button colorScheme={"green"} type="submit" mt={6}>
            Submit
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
