import React, { useState } from "react";
import {
  LanguageOptions,
  useProductSizesQuery,
  useCreateCustomProductSizeMutation,
  useProductQuery,
  useUpdateCustomProductSizeMutation,
} from "../../generated/graphql";
import {
  Box,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Text,
  Spinner,
  useToast,
  Button,
  IconButton,
  Flex,
} from "@chakra-ui/react";
import { CgCheck, CgCloseO, CgAdd, CgEditFade } from "react-icons/cg";
import PriceModal from "../../components/PriceModal";

const Size = ({ id }: { id: string }) => {
  const { data, loading, error, refetch } = useProductSizesQuery({
    variables: {
      where: {
        productId: {
          equals: id,
        },
      },
    },
    fetchPolicy: "network-only",
  });
  const { data: product } = useProductQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });
  const toast = useToast();
  const [createPrice, { loading: priceLoading }] = useCreateCustomProductSizeMutation();
  const [updatePrice] = useUpdateCustomProductSizeMutation();

  const handleCreatePrice = async (id: string, price: number) => {
    console.log("🌵💜🐢", price);

    try {
      const res = await createPrice({
        variables: {
          price: parseFloat(price.toFixed(2)),
          sizeId: id,
        },
      });
      if (res.data?.createCustomProductSize.id) {
        toast({
          title: "created size price",
          status: "success",
        });
        refetch();
      }
    } catch (error) {
      console.log("🌵💜🐢 error", error);
    }
  };

  const handleUpdatePrice = async (id: string, price: number, priceId?: string) => {
    try {
      console.log("🌵💜🐢", priceId);

      if (!priceId) {
        return toast({
          title: "error",
          status: "error",
        });
      }
      const res = await updatePrice({
        variables: {
          price: parseFloat(price.toFixed(2)),
          sizeId: id,
          priceId: priceId ? priceId : "",
        },
      });
      if (res.data?.updateCustomProductSize.id) {
        toast({
          title: "updated size price",
          status: "success",
        });
        refetch();
      }
    } catch (error) {
      console.log("🌵💜🐢 error", error);
    }
  };

  const renderSize = () => {
    const basePriceTRY = product?.product?.prices.find((b) => b.currency.code === "TRY");
    const basePriceUSD = product?.product?.prices.find((b) => b.currency.code === "USD");
    if (loading) {
      return <Spinner />;
    }
    return (
      data?.productSizes &&
      data.productSizes.map((s) => {
        const description = s.size.descriptions.find(
          (f) => f.language === LanguageOptions.TR
        );
        const tr = s.prices.find((t) => t.currency.code === "TRY");
        const usd = s.prices.find((t) => t.currency.code === "USD");
        return (
          <Tr key={s.id}>
            <Td>
              {s.size.sku} - {description?.title}
            </Td>
            <Td>
              {basePriceTRY ? basePriceTRY.price + (tr ? tr.price : 0) : tr?.price}
              {basePriceTRY?.currency.code} ({tr?.price} {tr?.currency.code}){" "}
            </Td>
            <Td>
              {basePriceUSD
                ? Number(basePriceUSD?.price + (usd ? usd.price : 0)).toFixed(2)
                : usd?.price}{" "}
              {basePriceUSD?.currency.code} ({usd?.price} {usd?.currency.code})
              {!usd ? (
                <PriceModal
                  id={s.id}
                  onSubmit={handleCreatePrice}
                  title={`${s.size.sku} price`}
                />
              ) : (
                <PriceModal
                  id={s.id}
                  defaultPrice={usd.price}
                  priceId={usd.id}
                  onSubmit={handleUpdatePrice}
                  title={`${s.size.sku} price`}
                />
              )}
            </Td>
          </Tr>
        );
      })
    );
  };

  return (
    <Box>
      <Text fontSize={"3xl"} fontWeight={"semibold"}>
        Product Size
      </Text>
      <Box bg={"white"} p={4} my={4}>
        <Table variant={"simple"}>
          <Thead>
            <Tr>
              <Th>Size</Th>
              <Th>TRY Price</Th>
              <Th>USD Price</Th>
            </Tr>
          </Thead>
          <Tbody>{renderSize()}</Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default Size;
