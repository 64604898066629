import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton } from "@chakra-ui/react";
import { useFindManyStaffQuery } from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import dayjs from "dayjs";

import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

const All = () => {
  const { loading, error, data, refetch } = useFindManyStaffQuery({
    variables: {},
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Yetkililer
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to="/staff/create"
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>

      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Kayıt</Th>
            <Th>İsim</Th>
            <Th>E-Posta</Th>

            <Th textAlign={"center"}>İşlem</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.findManyStaff.map((user, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{dayjs(user.createdAt).format("DD MMM YYYY")}</Td>
                <Td>{`${user.name} ${user.surname}`}</Td>
                <Td>{user.email}</Td>

                <Td>
                  <Flex justifyContent="center">
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/staff/update/${user.id}`}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
