import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

import {
  CreateCurrencyMutationVariables,
  CurrencyQuery,
  UpdateCurrencyMutationVariables,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { createSlug } from "../../utils/slug";

interface Props {
  handleCreate?: (data: CreateCurrencyMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateCurrencyMutationVariables) => Promise<any>;
  id?: string;
  data?: CurrencyQuery["currency"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [prefix, setPrefix] = useState(data?.prefix || "");
  const [suffix, setSuffix] = useState(data?.suffix || "");
  const [buy, setBuy] = useState(data?.buy || 0);
  const [sell, setSell] = useState(data?.sell || 0);
  const [code, setCode] = useState(data?.code || "");
  const [autoUpdate, setAutoUpdate] = useState(data?.autoUpdate);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Başlık gereklidir",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!buy && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Alış fiyatı gereklidir",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!code && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Kod gereklidir",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!sell && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Satış fiyatı gereklidir",
        title: "Hata",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title,
                  description,
                  slug: createSlug(title),
                },
              ],
            },
            buy,
            code,
            sell,
            prefix,
            suffix,
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdate!({
          where: {
            id: id,
          },
          data: {
            buy: { set: buy },
            sell: { set: sell },
            code: { set: code },
            prefix: { set: prefix },
            suffix: { set: suffix },
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Para Birimi {actionType === "CREATE" ? "Ekle" : "Düzenle"}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/currency`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Başlık</FormLabel>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Başlık"
                />
              </GridItem>
            )}

            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Açıklama</FormLabel>
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  initialValue={undefined}
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Açıklama...",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            )}

            <GridItem colSpan={2}>
              <FormLabel>Kod</FormLabel>
              <Input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder="Örneğin TRY, EUR, USD"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Ön Ek</FormLabel>
              <Input
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
                placeholder="Ön eki olan para birimleri"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Son Ek</FormLabel>
              <Input
                value={suffix}
                onChange={(e) => setSuffix(e.target.value)}
                placeholder="Son eki olan para birimleri"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Alış</FormLabel>
              <Input
                value={buy}
                onChange={(e) => setBuy(Number(e.target.value))}
                placeholder="Alış"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Satış</FormLabel>
              <Input
                value={sell}
                onChange={(e) => setSell(Number(e.target.value))}
                placeholder="Satış"
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Otomatik Güncelleme</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={autoUpdate ? "green" : "gray"}
                    onClick={() => setAutoUpdate(true)}
                  >
                    Aktif
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!autoUpdate ? "red" : "gray"}
                    onClick={() => setAutoUpdate(false)}
                  >
                    Pasif
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Button colorScheme="green" type="submit" mt={6}>
            Kaydet
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
