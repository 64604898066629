import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import All from "./All";
import Bold from "./Bold";
import Create from "./Create";
import Detail from "./Detail";
import Update from "./Update";
import Order from "./Order";
import BoldOrder from "./BoldOrder";
import Size from "./Size";
const Product = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      case "create":
        setComponent(<Create />);
        break;
      case "sinemis":
        setComponent(<All />);
        break;
      case "bold":
        if (pathname[3] === "order") {
          return setComponent(<BoldOrder />);
        }
        setComponent(<Bold />);
        break;
      case "order":
        setComponent(<Order />);
        break;
      case "update":
        setComponent(<Update id={pathname[3]} />);
        break;
      case "detail":
        setComponent(<Detail id={pathname[3]} />);
        break;
      case "size":
        setComponent(<Size id={pathname[3]} />);
        break;
      default:
        setComponent(<All />);
    }
  }, [location]);

  return component;
};

export default Product;
