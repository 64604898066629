import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  Avatar,
  Wrap,
  WrapItem,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  SortOrder,
  useDeleteContentMutation,
  useContentsQuery,
  ContentType,
  MediaType,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage, FcUndo, FcStart } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import Description from "../../components/description/Description";
import { IoMdReorder } from "react-icons/io";

const All = () => {
  const params = useParams<{
    id: string;
    field: "page";
  }>();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteContentMutation();
  const toast = useToast();
  const { loading, error, data, refetch } = useContentsQuery({
    variables: {
      where: {
        [params.field!]: {
          is: {
            id: {
              equals: params.id,
            },
          },
        },
      },
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });

      return toast({
        duration: 1500,
        title: "Başarılı",
        status: "success",
        description: "Silme işlemi tamamlandı",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Hata",
        status: "error",
        description: error.message || "Bir hata oluştu",
      });
    }
  };
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          İçerik Listesi
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcUndo />}
            aria-label="Go Back"
            as={Link}
            to={`/${params.field}`}
          />
          <IconButton
            to={`/content/${params.field}/${params.id}/order`}
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to={`/content/${params.field}/${params.id}/create`}
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Content</Th>
            <Th textAlign={"center"}>Content Type</Th>
            <Th textAlign={"center"}>Media Type</Th>
            <Th textAlign={"center"}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.contents.map((content, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td maxWidth={"300px"}>
                  {content.contentType === ContentType.TEXT && (
                    <Description
                      descriptions={content.descriptions}
                      field="content"
                      id={content.id}
                      refetch={refetch}
                    />
                  )}
                  {content.contentType === ContentType.IMAGE &&
                    (content.mediaType === MediaType.GALLERY ? (
                      <Wrap>
                        {content.photos.map((p, i) => (
                          <WrapItem key={i}>
                            <Avatar
                              size={"sm"}
                              src={`${process.env.REACT_APP_CDN}/${p}`}
                            />
                          </WrapItem>
                        ))}
                      </Wrap>
                    ) : (
                      <Description
                        descriptions={content.descriptions}
                        field="content"
                        id={content.id}
                        refetch={refetch}
                      />
                    ))}
                  {content.contentType === ContentType.VIDEO && content.video && (
                    <Flex>
                      <a href={content.video} target="_blank" rel="noreferrer">
                        <Icon as={FcStart} fontSize={"20px"} mr={2} />
                      </a>
                      {content.video.replace(/.+\/\/|www.|\..+/g, "").toUpperCase()} Video
                    </Flex>
                  )}
                </Td>
                <Td textAlign={"center"}>{content.contentType}</Td>
                <Td textAlign={"center"}>
                  {content.contentType === ContentType.IMAGE ? content.mediaType : "-"}
                </Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    {content.contentType !== ContentType.TEXT && (
                      <Box mx={2}>
                        <IconButton
                          aria-label="Edit"
                          icon={<FcEditImage />}
                          as={Link}
                          to={`/content/${params.field}/${params.id}/update/${content.id}`}
                        />
                      </Box>
                    )}
                    <Box mx={2}>
                      <DeleteItem
                        id={content.id}
                        loading={deleteLoading}
                        title={`${
                          content.contentType === ContentType.TEXT
                            ? content.descriptions[0].title
                            : "Content"
                        }`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
