import {
  Badge,
  Box,
  Button,
  chakra,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { BsCartFill, BsCurrencyDollar } from "react-icons/bs";

import SkeletonGroup from "../components/ui/SkeletonGroup";
import { useDashboardStatisticsQuery } from "../generated/graphql";

function Dashboard() {
  const { data } = useDashboardStatisticsQuery();
  return (
    <>
      <Box p={4} bgColor="white" shadow="md" rounded="base" mb={12}>
        <Heading mb={1} size="lg">
          Sinemis ⚙️
        </Heading>
      </Box>
      <Box p={4} bgColor="white" shadow="md" rounded="base">
        <Heading mb={1} size="lg">
          İstatistikler
        </Heading>
        <Divider />

        {!data ? (
          <Box p={6} bgColor="white" my={4}>
            <SkeletonGroup amount={6} />
          </Box>
        ) : (
          <>
            <SimpleGrid
              columns={{
                base: 1,
                md: 4,
              }}
              gap={4}
            >
              <Box p={6} bg="white" rounded="md" display="flex" flexDir="column">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Icon as={BsCartFill} mr={2} color="blue.400" />{" "}
                    <chakra.span fontWeight="semibold">Siparişler</chakra.span>
                  </Box>
                  <Badge>Bugün</Badge>
                </Box>
                <Heading size="lg" mt={2}>
                  {data.dashboardStatistics.todayOrderCount}
                </Heading>
              </Box>

              <Box p={6} bg="white" rounded="md" display="flex" flexDir="column">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Icon as={BsCurrencyDollar} mr={2} color="blue.400" />{" "}
                    <chakra.span fontWeight="semibold">Kazanç</chakra.span>
                  </Box>
                  <Badge>Bugün</Badge>
                </Box>
                <Heading size="lg" mt={2}>
                  {data.dashboardStatistics.todayEarnings}
                </Heading>
              </Box>

              <Box p={6} bg="white" rounded="md" display="flex" flexDir="column">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Icon as={BsCartFill} mr={2} color="blue.400" />{" "}
                    <chakra.span fontWeight="semibold">Siparişler</chakra.span>
                  </Box>
                  <Badge colorScheme="pink">7 gün</Badge>
                </Box>
                <Heading size="lg" mt={2}>
                  {data.dashboardStatistics.weeklyOrderCount}
                </Heading>
              </Box>

              <Box p={6} bg="white" rounded="md" display="flex" flexDir="column">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Icon as={BsCurrencyDollar} mr={2} color="blue.400" />{" "}
                    <chakra.span fontWeight="semibold">Kazanç</chakra.span>
                  </Box>
                  <Badge colorScheme="pink">7 gün</Badge>
                </Box>
                <Heading size="lg" mt={2}>
                  {data.dashboardStatistics.weeklyEarnings}
                </Heading>
              </Box>
            </SimpleGrid>

            <Box>
              <Heading my={4} size="md">
                Bekleyen Siparişler
              </Heading>
              <Flex overflow="auto">
                <Table variant="custom">
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Tarih</Th>
                      <Th>Müşteri</Th>
                      <Th>Ürün Sayısı</Th>
                      <Th>Toplam Ücret</Th>
                      <Th>Ödenen Ücret</Th>
                      <Th>Durum</Th>
                      <Th isNumeric></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.dashboardStatistics.pendingOrders?.map((order, i) => (
                      <Tr key={order.id}>
                        <Td w="4%">{i + 1}</Td>
                        <Td w="10%">
                          {dayjs(order.createdAt).format("DD/MM/YYYY HH:mm")}
                        </Td>
                        <Td>{`${order.customer.name} ${order.customer.surname}`}</Td>
                        <Td>{order._count?.products}</Td>
                        <Td>{order.payment?.price}</Td>
                        <Td>{order.payment?.paidPrice}</Td>
                        <Td>
                          <Badge colorScheme="blue">{order.orderStatus || ""}</Badge>
                        </Td>
                        <Td isNumeric>
                          <Button size="sm" as={Link} to={`/orders/detail/${order.id}`}>
                            Detay
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

export default Dashboard;
