import { Avatar, AvatarBadge, Box, Flex, Heading } from "@chakra-ui/react";
import { useStaffStore } from "../../store/staff";
import Sidebar from "./Sidebar/Sidebar";

type Props = {
  children: React.ReactNode;
};
function Layout({ children }: Props) {
  const { staff } = useStaffStore();

  return (
    <Flex
      flexDirection={{
        base: "column",
        md: "row",
      }}
      height="100vh"
    >
      {/* LEFT SIDEBAR */}
      <Sidebar />
      <Flex
        width={{ base: "full", md: "85%" }}
        flexDir="column"
        bgColor="gray.100"
        minH="100vh"
        py={4}
        px={5}
        overflow="auto"
      >
        <Box p={4} mb={6} bgColor="white" shadow="md" rounded="base">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Heading fontSize="lg"></Heading>
            <Box display="flex" alignItems="center">
              <Heading textAlign="right" fontSize="lg" mr={3}>
                {staff?.name}
              </Heading>
              <Avatar size="sm" name={staff?.name || undefined}>
                <AvatarBadge borderColor="papayawhip" bg="green.500" boxSize="1.25em" />
              </Avatar>
            </Box>
          </Box>
        </Box>
        {children}
      </Flex>
    </Flex>
  );
}

export default Layout;
