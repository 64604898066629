import { Fragment } from "react";
import {
  Box,
  Flex,
  IconButton,
  SimpleGrid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { useCustomerQuery } from "../../generated/graphql";
import { FcRefresh, FcEditImage } from "react-icons/fc";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

interface Props {
  id: string;
}

const Detail: React.VFC<Props> = ({ id }) => {
  const { loading, error, data, refetch } = useCustomerQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Fragment>
      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Customer Detail
          </Box>
          <Flex experimental_spaceX={4}>
            <IconButton
              icon={<FcRefresh />}
              aria-label="Refetch Query"
              onClick={() => refetch()}
            />
            <IconButton
              to={`/user/update/${id}`}
              as={Link}
              icon={<FcEditImage />}
              aria-label="Add Item"
              onClick={() => refetch()}
            />
          </Flex>
        </Flex>
        <SimpleGrid columns={2} gap={6}>
          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Registered At" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${dayjs(data?.customer!.createdAt).format("DD MMM YYYY HH:mm")}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Name Surname" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.customer!.name} ${data?.customer!.surname}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="E-Mail" />
              <Input fontSize={"sm"} disabled value={`${data?.customer!.email}`} />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Phone Number" />
              <Input fontSize={"sm"} disabled value={`${data?.customer!.phoneNumber}`} />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Phone Verified" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.customer!.phoneActivated ? "Verified" : "Not Verified"}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="E-Mail Verified" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.customer!.emailActivated ? "Verified" : "Not Verified"}`}
              />
            </InputGroup>
          </GridItem>
        </SimpleGrid>
      </PageLayout>
    </Fragment>
  );
};

export default Detail;
