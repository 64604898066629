import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  SimpleGrid,
  GridItem,
  FormLabel,
  Input,
  Flex,
  IconButton,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  useUpdatePriceMutation,
  usePriceQuery,
  PriceQuery,
} from "../../generated/graphql";
import PageLayout from "../../components/ui/PageLayout";
import { FcList } from "react-icons/fc";
import SkeletonGroup from "../../components/ui/SkeletonGroup";

interface IPriceInputProps {
  productId: string | undefined;
  data: PriceQuery;
}

const PriceInput: React.VFC<IPriceInputProps> = ({ productId, data }) => {
  // const productId = params.productId;
  const navigation = useNavigate();
  const [updatePrice, { loading: updateLoading }] = useUpdatePriceMutation();
  const [price, setPrice] = useState(data.price?.price!);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await updatePrice({
      variables: {
        productId: productId!,
        price: Number(price.toFixed(2)),
      },
    });
    return navigation("/product");
  };
  return (
    <form onSubmit={handleSubmit}>
      <Box
        width={{
          base: "100%",
          md: "50%",
        }}
      >
        <Alert status="info" mb={4}>
          <AlertIcon />
          Türk Lirası fiyatını düzenliyorsunuz. Dolar fiyatı otomatik hesaplanmaktadır.
        </Alert>
        <SimpleGrid columns={2} gap={6}>
          <GridItem colSpan={2}>
            <FormLabel>Fiyat</FormLabel>
            <Input
              type="number"
              step={0.1}
              min={0}
              value={price}
              onChange={(e) => setPrice(Number(e.target.value))}
              placeholder="Ürün fiyatı"
            />
          </GridItem>
        </SimpleGrid>
        <Button
          colorScheme="green"
          type="submit"
          mt={6}
          isDisabled={updateLoading}
          isLoading={updateLoading}
        >
          Kaydet
        </Button>
      </Box>
    </form>
  );
};

const Form = () => {
  const params = useParams();
  const priceId = params.priceId;
  const productId = params.productId;

  const { data, loading } = usePriceQuery({ variables: { where: { id: priceId } } });

  console.log("data 👉", data);

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Fiyat Düzenle
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            as={Link}
            to={"/product"}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      {loading ? (
        <SkeletonGroup amount={2} />
      ) : (
        <PriceInput productId={productId} data={data!} />
      )}
    </PageLayout>
  );
};

export default Form;
