import { Box, Flex, IconButton, Table, Th, Thead, Tr } from "@chakra-ui/react";
import { FcList } from "react-icons/fc";
import { Link } from "react-router-dom";
import Error from "../../components/error/Error";
import Loading from "../../components/loading/Loading";
import PageLayout from "../../components/ui/PageLayout";
import {
  SizesQuery,
  SortOrder,
  useOrderSizeMutation,
  useSizesQuery,
} from "../../generated/graphql";
import OrderList from "./OrderList";

function Order() {
  const { loading, error, data } = useSizesQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });

  const [order] = useOrderSizeMutation();

  const setOrder = (data: SizesQuery["sizes"]) => {
    data!.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error: any) {
        console.log("err 👉", error);
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Boyutları Sırala
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/size" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>

      <Table maxW="2xl" mx="auto">
        <Thead>
          <Tr>
            <Th textAlign="center">#</Th>
            <Th textAlign="center">Başlık</Th>
          </Tr>
        </Thead>
        <OrderList items={data?.sizes!} setOrder={setOrder} />
      </Table>
    </PageLayout>
  );
}

export default Order;
