import { Flex, Stack, Button, Heading } from "@chakra-ui/react";
import { BsBoxSeam, BsSpeedometer } from "react-icons/bs";
import {
  FiUsers,
  FiLayout,
  FiDollarSign,
  FiPercent,
  FiShoppingCart,
} from "react-icons/fi";
import { VscSymbolClass } from "react-icons/vsc";
import { GiPearlNecklace } from "react-icons/gi";
import { FaUserCog } from "react-icons/fa";

import { useStaffStore } from "../../../store/staff";
import SidebarMenuItem from "./SidebarMenuItem";

function Sidebar() {
  const { logoutStaff } = useStaffStore();

  return (
    <Flex
      flexDirection="column"
      bgColor="gray.800"
      width={{
        base: "full",
        md: "15%",
      }}
      py={4}
      px={2}
    >
      <Stack direction="column" spacing={2} color="white" alignContent="start">
        <Heading px={2} fontSize="lg" mb={6}>
          SiNEMiS
        </Heading>
        <SidebarMenuItem icon={BsSpeedometer} title="Menu" url="/" />
        <SidebarMenuItem icon={FiUsers} title="Müşteriler" url="/customer" />
        <SidebarMenuItem icon={FiShoppingCart} title="Siparişler" url="/orders" />
        <SidebarMenuItem icon={VscSymbolClass} title="Kategoriler" url="/category" />
        <SidebarMenuItem icon={GiPearlNecklace} title="Koleksiyonlar" url="/collection" />
        <SidebarMenuItem icon={GiPearlNecklace} title="Boyutlar" url="/size" />
        <SidebarMenuItem icon={GiPearlNecklace} title="Renkler" url="/color" />
        <SidebarMenuItem
          icon={BsBoxSeam}
          title="Sinemis Ürünleri"
          url="/product/sinemis"
        />
        <SidebarMenuItem
          icon={BsBoxSeam}
          title="Bold Pieces Ürünleri"
          url="/product/bold"
        />
        <SidebarMenuItem icon={FiDollarSign} title="Para Birimleri" url="/currency" />
        <SidebarMenuItem
          icon={FiPercent}
          title="İndirim Kuponları"
          url="/discountcoupon"
        />

        {/* cms */}
        <SidebarMenuItem icon={FiLayout} title="Sayfalar" url="/page" />
        <SidebarMenuItem icon={FaUserCog} title="Yetkililer" url="/staff" />
      </Stack>

      <Button
        mt="auto"
        colorScheme="red"
        onClick={() => {
          logoutStaff();
          window.location.href = "/";
        }}
      >
        Çıkış Yap
      </Button>
    </Flex>
  );
}

export default Sidebar;
