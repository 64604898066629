import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { CollectionsQuery, LanguageOptions } from "../../generated/graphql";
import { Tbody, Td, Tr } from "@chakra-ui/react";

const SortableItem = SortableElement(
  ({
    value,
    productOrder,
  }: {
    value: CollectionsQuery["collections"][0];
    productOrder: number;
  }) => (
    <Tr>
      <Td textAlign="center">{productOrder + 1}</Td>
      <Td textAlign="center">
        {value.descriptions.find((d) => d.language === LanguageOptions.TR)?.title}
      </Td>
    </Tr>
  )
);

const SortableList = SortableContainer(
  ({ items }: { items: CollectionsQuery["collections"] }) => {
    return (
      <Tbody>
        {items.map((value, index) => {
          return (
            <SortableItem
              key={`item-${index}`}
              index={index}
              productOrder={index}
              value={value}
            />
          );
        })}
      </Tbody>
    );
  }
);

class OrderList extends React.Component<
  {
    items: CollectionsQuery["collections"];
    setOrder: (data: any) => void;
  },
  { setOrder: any; items: CollectionsQuery["collections"] }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    this.setState(({ items }) => {
      const newOrder = arrayMoveImmutable(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

export default OrderList;
