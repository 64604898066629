import { Icon, CircularProgressProps } from "@chakra-ui/react";
import { BsFillCheckSquareFill, BsFillXSquareFill } from "react-icons/bs";
import CircularLoading from "../circularLoading/CircularLoading";

type Props = {
  status: boolean;
  onClick?: React.MouseEventHandler<SVGElement> | undefined;
  loading?: boolean;
  progressProps?: CircularProgressProps;
};

function StatusBox({ status, onClick, loading, progressProps }: Props) {
  if (loading) {
    return <CircularLoading progressProps={progressProps} />;
  }
  return status ? (
    <Icon as={BsFillCheckSquareFill} color="green.500" w={4} h={4} onClick={onClick} />
  ) : (
    <Icon as={BsFillXSquareFill} color="red.500" w={4} h={4} onClick={onClick} />
  );
}

export default StatusBox;
