import { Box, Table, Thead, Tbody, Th, Tr, Td, Flex, IconButton } from "@chakra-ui/react";
import { SortOrder, useOrdersQuery } from "../../generated/graphql";
import { FcRefresh, FcAbout } from "react-icons/fc";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

const All = () => {
  const { loading, error, data, refetch } = useOrdersQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Siparişler
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Sipariş Tarihi</Th>
            <Th>Müşteri</Th>
            <Th>Ücret</Th>
            <Th>Ödenen</Th>
            <Th>Durum</Th>
            <Th textAlign={"center"}>Bilgi</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.orders.map((order, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td>{dayjs(order.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Td>
                <Td>{`${order.customer.name} ${order.customer.surname}`}</Td>
                <Td>
                  {order.payment?.price} {order.payment?.currency.code}
                </Td>
                <Td>
                  {order.payment?.paidPrice} {order.payment?.currency.code}
                </Td>
                <Td>{order.orderStatus}</Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcAbout />}
                        as={Link}
                        to={`/orders/detail/${order.id}`}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
