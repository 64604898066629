import { BrowserRouter, Routes, Route } from "react-router-dom";

import RequireAuth from "./RequireAuth";
import NotRequireAuth from "./NotRequireAuth";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Page from "../pages/Page/Page";
import Description from "../pages/Description/Form";
import Price from "../pages/Price/Form";
import Content from "../pages/Content/Content";
import Customer from "../pages/Customer/Customer";
import Category from "../pages/Category/Category";
import Collection from "../pages/Collection/Collection";
import Size from "../pages/Size/Size";
import Product from "../pages/Product/Product";
import Currency from "../pages/Currency/Currency";
import DiscountCoupon from "../pages/DiscountCoupon/DiscountCoupon";
import Order from "../pages/Order/Order";
import Staff from "../pages/Staff/Staff";
import Color from "../pages/Color/Color";
import PriceHistory from "../pages/PriceHistory/PriceHistory";
function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NotRequireAuth />}>
          <Route index element={<Login />} />
        </Route>
        <Route element={<RequireAuth />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/customer/*" element={<Customer />} />
          <Route path="/staff/*" element={<Staff />} />
          <Route path="/category/*" element={<Category />} />
          <Route path="/collection/*" element={<Collection />} />
          <Route path="/size/*" element={<Size />} />
          <Route path="/color/*" element={<Color />} />
          <Route path="/product/*" element={<Product />} />
          <Route path="/currency/*" element={<Currency />} />
          <Route path="/page/*" element={<Page />} />
          <Route path="/discountcoupon/*" element={<DiscountCoupon />} />
          <Route path="/orders/*" element={<Order />} />
          <Route path="/content/:field/:id/*" element={<Content />} />
          <Route
            path="/description/:action/:language/:field/:fieldId/*"
            element={<Description />}
          />
          <Route path="/price/:productId/:priceId/*" element={<Price />} />
          <Route path="/priceHistory/:id" element={<PriceHistory />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
