import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import All from "./All";
import Detail from "./Detail";

const Order = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[2]) {
      case "detail":
        setComponent(<Detail id={pathname[3]} />);
        break;
      default:
        setComponent(<All />);
    }
  }, [location]);

  return component;
};

export default Order;
