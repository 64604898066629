import React from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Container,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Select,
  Stack,
} from "@chakra-ui/react";
import { FcList } from "react-icons/fc";
import { Link } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import PageLayout from "../../components/ui/PageLayout";
import {
  BrandType,
  ProductsQuery,
  SortOrder,
  useCategoriesQuery,
  useOrderProductMutation,
  useProductsLazyQuery,
} from "../../generated/graphql";
import SkeletonGroup from "../../components/ui/SkeletonGroup";

function Order() {
  const [order] = useOrderProductMutation();

  const { data: categoriesData, loading: categoriesLoading } = useCategoriesQuery();
  const [getProducts, { data: productsData, loading: productsLoading }] =
    useProductsLazyQuery();

  const onCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    getProducts({
      variables: {
        where: {
          brand: { equals: BrandType.BOLDPIECES },
          category: { is: { id: { equals: e.target.value } } },
        },
        orderBy: { order: SortOrder.asc },
      },
    });
  };

  const setOrder = (data: ProductsQuery["products"]["items"]) => {
    data!.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error: any) {
        console.log("err 👉", error);
      }
    });
  };

  /* if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  } */

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize="18px" fontWeight="bold">
          Ürünleri Sırala
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/product" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>

      <FormControl isDisabled={categoriesLoading} w="50%" mx="auto" mb="4">
        <FormLabel>Kategori Seçimi</FormLabel>
        <Select onChange={onCategoryChange}>
          <option value={undefined}></option>
          {categoriesData &&
            categoriesData.categories?.map((category) => (
              <option key={category.id} value={category.id}>
                {category.descriptions.length > 1
                  ? category.descriptions.map((desc) => `(${desc.title}) `)
                  : category.descriptions.map((desc) => `${desc.title}`)}
              </option>
            ))}
        </Select>
      </FormControl>

      <Container maxW="container.md">
        <Stack>
          {productsLoading ? (
            <SkeletonGroup amount={8} />
          ) : (
            productsData &&
            (productsData.products.count === 0 ? (
              <Alert status="warning">
                <AlertIcon />
                Bu kategoride ürün yok.
              </Alert>
            ) : (
              <SortableTable setOrder={setOrder} items={productsData!.products.items} />
            ))
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}

class SortableTable extends React.Component<
  {
    items: ProductsQuery["products"]["items"];
    setOrder: (data: any) => void;
  },
  { setOrder: any; items: any }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      items: this.props.items,
      setOrder: this.props.setOrder,
    };
  }

  onSortEnd = ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => {
    if (oldIndex === newIndex) return;
    this.setState(({ items }) => {
      const newOrder = arrayMoveImmutable(items, oldIndex, newIndex);
      this.state.setOrder(newOrder);
      return {
        items: newOrder,
      };
    });
  };

  render() {
    return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
  }
}

const SortableList = SortableContainer(
  ({ items }: { items: ProductsQuery["products"]["items"] }) => {
    return (
      <Container maxW="container.md">
        {items?.map((product, i) => (
          <TableItem key={product.id} item={product} index={i} />
        ))}
      </Container>
    );
  }
);

const TableItem = SortableElement(
  ({ item }: { item: ProductsQuery["products"]["items"][0] }) => {
    return (
      <>
        <Box
          key={item.id}
          bgColor="gray.200"
          textAlign="center"
          py="1"
          mb="2"
          rounded="md"
          _hover={{ cursor: "grab" }}
        >
          {item.descriptions[0]?.title}
        </Box>
      </>
    );
  }
);

export default Order;
