import React from "react";
import { useParams } from "react-router-dom";
import {
  SortOrder,
  useDescriptionQuery,
  usePriceHistoriesQuery,
  useProductQuery,
  useProductsQuery,
} from "../../generated/graphql";
import { Box, Flex, Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import PageLayout from "../../components/ui/PageLayout";
import dayjs from "dayjs";

const PriceHistory = () => {
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = usePriceHistoriesQuery({
    variables: {
      where: {
        product: {
          is: {
            id: {
              equals: id,
            },
          },
        },
      },
      orderBy: {
        createdAt: SortOrder.desc,
      },
    },
  });
  const { data: productData } = useProductsQuery({
    variables: {
      where: {
        id: {
          equals: id,
        },
      },
      take: 1,
    },
  });
  if (loading) return <Spinner />;
  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          {productData?.products.items[0].descriptions[0].title} Fiyat Geçmişi
        </Box>
      </Flex>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Fiyat</Th>
            <Th>Güncellenme Tarihi</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data &&
            data?.priceHistories.map((price, i) => {
              return (
                <Tr key={i + 1}>
                  <Td>
                    {price.price} {price.currency.code}
                  </Td>
                  <Td>{dayjs(price.createdAt).format("DD/MM/YYYY HH:mm:ss")}</Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default PriceHistory;
