import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import All from "./All";
import Create from "./Create";
import Update from "./Update";
import Order from "./Order";

const Content = () => {
  const [component, setComponent] = useState(<Loading />);
  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname.split("/");
    switch (pathname[4]) {
      case "create":
        setComponent(<Create />);
        break;
      case "order":
        setComponent(<Order />);
        break;
      case "update":
        setComponent(<Update id={pathname[5]} />);
        break;
      default:
        setComponent(<All />);
    }
  }, [location]);

  return component;
};

export default Content;
