import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonGroup,
  IconButton,
  useToast,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  chakra,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsPercent, BsDash } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import {
  DiscountType,
  useDeleteManyBasketItemMutation,
  useUpdateProductMutation,
} from "../generated/graphql";

type Props = {
  refetch: () => any;
  productId: string;
  discountAmount: number;
  discountType: DiscountType;
  name: string;
};

type Inputs = {
  discountAmount: number;
  discountType: DiscountType;
};

function DiscountModal(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const cancelRef = useRef(null);
  const toast = useToast();

  const [updateProduct, { loading }] = useUpdateProductMutation();
  const [deleteManyBasketItem, { loading: deleteMBILoading }] =
    useDeleteManyBasketItemMutation();

  const { register, handleSubmit, setValue, watch } = useForm<Inputs>({
    defaultValues: {
      discountType: props.discountType,
      discountAmount: props.discountAmount,
    },
  });
  const onClose = () => {
    setIsOpen(false);
  };

  const onSubmit = async (formData: Inputs) => {
    try {
      await updateProduct({
        variables: {
          where: {
            id: props.productId,
          },
          data: {
            discountAmount: {
              set: parseFloat(formData.discountAmount as unknown as string),
            },
            discountType: { set: formData.discountType },
          },
        },
      });

      await deleteManyBasketItem({
        variables: {
          where: {
            productId: { equals: props.productId },
          },
        },
      });

      onClose();

      return toast({
        title: "Başarılı",
        description: "İndirim uygulandı",
        status: "success",
        duration: 1000,
        isClosable: true,
        onCloseComplete: async () => {
          await props.refetch();
        },
      });
    } catch (err: any) {
      return toast({
        title: "Hata",
        description: err.message || "Bir hata oluştu",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <ButtonGroup isAttached>
        <IconButton
          aria-label="discount"
          icon={<BsPercent />}
          onClick={() => setIsOpen(true)}
        />
        {props.discountAmount !== 0 && (
          <Button
            variant="outline"
            onClick={() => {
              setValue("discountAmount", 0);
              handleSubmit(onSubmit)();
            }}
            leftIcon={<MdCancel color="#f42020" />}
          >
            {props.discountAmount}
          </Button>
        )}
      </ButtonGroup>

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              "{props.name}" ürüne indirim uygula
            </AlertDialogHeader>

            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <AlertDialogBody>
                <FormControl mb={3}>
                  <FormLabel>Tür</FormLabel>
                  <Select {...register("discountType")}>
                    {Object.values(DiscountType).map((type, i) => (
                      <option key={i}>{type}</option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl>
                  <FormLabel>Miktar</FormLabel>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        watch("discountType") === DiscountType.PERCENTAGE ? (
                          <BsPercent color="gray" />
                        ) : (
                          <BsDash color="gray" />
                        )
                      }
                    />
                    <Input
                      type="number"
                      step={0.1}
                      {...register("discountAmount")}
                      placeholder="İndirim miktarı"
                    />
                  </InputGroup>
                </FormControl>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  Vazgeç
                </Button>
                <Button
                  colorScheme="green"
                  type="submit"
                  ml={3}
                  isDisabled={loading || deleteMBILoading}
                >
                  Kaydet
                </Button>
              </AlertDialogFooter>
            </chakra.form>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default DiscountModal;
