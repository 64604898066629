import { Box, Flex, IconButton, Table, Th, Thead, Tr } from "@chakra-ui/react";
import { FcList } from "react-icons/fc";
import { Link, useParams } from "react-router-dom";
import Error from "../../components/error/Error";
import Loading from "../../components/loading/Loading";
import PageLayout from "../../components/ui/PageLayout";
import {
  ContentsQuery,
  SortOrder,
  useContentsQuery,
  useOrderContentMutation,
} from "../../generated/graphql";
import OrderList from "./OrderList";

function Order() {
  const params = useParams<{
    id: string;
    field: "page";
  }>();
  const { loading, error, data } = useContentsQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
      where: {
        pageId: { equals: params.id },
      },
    },
    fetchPolicy: "network-only",
  });

  const [order] = useOrderContentMutation();

  const setOrder = (data: ContentsQuery["contents"]) => {
    data!.forEach(async (element, i) => {
      try {
        await order({
          variables: {
            id: element.id,
            order: i + 1,
          },
        });
      } catch (error: any) {
        console.log("err 👉", error);
      }
    });
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error />;
  }

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          İçerikleri Sırala
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/page" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>

      <Table maxW="2xl" mx="auto">
        <Thead>
          <Tr>
            <Th textAlign="center">#</Th>
            <Th textAlign="center">Başlık</Th>
          </Tr>
        </Thead>
        <OrderList items={data?.contents!} setOrder={setOrder} />
      </Table>
    </PageLayout>
  );
}

export default Order;
