import {
  Box,
  Container,
  SimpleGrid,
  GridItem,
  Button,
  FormControl,
  FormLabel,
  Input,
  chakra,
  useToast,
  FormHelperText,
  Image,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useStaffLoginMutation } from "../generated/graphql";
import { useStaffStore } from "../store/staff";

type Inputs = {
  email: string;
  password: string;
};

const schema: yup.SchemaOf<Inputs> = yup.object({
  email: yup.string().email().required(),
  password: yup.string().min(3).required(),
});

function Login() {
  const loginUser = useStaffStore((store) => store.loginStaff);
  const [loginMutation, { loading }] = useStaffLoginMutation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: Inputs) => {
    try {
      const res = await loginMutation({ variables: { data } });

      loginUser(res.data!.staffLogin);
      window.location.href = "/";
    } catch (error: any) {
      return toast({
        title: "Hata",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <SimpleGrid columns={{ base: 1, md: 4 }} gap={0} height="100vh">
      <GridItem bgColor="white" colSpan={3}>
        <Container>
          <Box display="flex" flexDir="column" alignItems="center" pt={5}>
            <Image src="/logo.png" w="300px" mb={16} />

            <chakra.form
              w="full"
              experimental_spaceY={8}
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl>
                <FormLabel>E-Posta</FormLabel>
                <Input size="lg" {...register("email", { required: true })} />
                <FormHelperText color="red.500">{errors.email?.message}</FormHelperText>
              </FormControl>
              <FormControl>
                <FormLabel>Şifre</FormLabel>
                <Input type="password" size="lg" {...register("password")} />
                <FormHelperText color="red.500">
                  {errors.password?.message}
                </FormHelperText>
              </FormControl>

              <Box display="flex" justifyContent="center">
                <Button
                  colorScheme="blue"
                  size="lg"
                  type="submit"
                  isDisabled={loading}
                  isLoading={loading}
                >
                  Giriş Yap
                </Button>
              </Box>
            </chakra.form>
          </Box>
        </Container>
      </GridItem>
      <GridItem colSpan={1} bgGradient="linear(to-b, gray.300, gray.600)" />
    </SimpleGrid>
  );
}

export default Login;
