import React from "react";
import { Box, Flex, Icon, IconButton, useToast } from "@chakra-ui/react";
import { FcEditImage, FcPlus } from "react-icons/fc";
import { Link } from "react-router-dom";
import { $enum } from "ts-enum-util";
import {
  Description as DescriptionModel,
  LanguageOptions,
  useDeleteDescriptionMutation,
} from "../../generated/graphql";
import DeleteItem from "../delete/DeleteItem";

export type OptionType = {
  showTitle: boolean;
};
interface Props {
  field: string;
  id: string;
  descriptions: DescriptionModel[];
  refetch: any;
  customLink?: string;
}

const Description: React.VFC<Props> = ({
  field,
  id,
  descriptions,
  refetch,
  customLink,
}) => {
  const [deleteDescription, { loading: deleteLoading }] = useDeleteDescriptionMutation();
  const toast = useToast();
  const handleDelete = async (id: string) => {
    try {
      await deleteDescription({
        variables: {
          where: {
            id,
          },
        },
      });
      return toast({
        duration: 1500,
        title: "Başarılı",
        status: "success",
        description: "Silme işlemi tamamlandı",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Hata",
        status: "error",
        description: error.message || "Bir hata oluştu",
      });
    }
  };

  return (
    <Box>
      {$enum(LanguageOptions).map((language) => {
        const description = descriptions.filter((d) => d.language === language);
        return description.length === 0 ? (
          <Flex
            key={language}
            borderBottom={"1px solid"}
            borderColor="gray.200"
            alignItems={"center"}
            py={2}
          >
            <Flex
              color={"white"}
              width={"23px"}
              height="23px"
              backgroundColor={"green.600"}
              rounded={"full"}
              fontSize={"10px"}
              fontWeight="semibold"
              justifyContent="center"
              alignItems={"center"}
              mr={2}
            >
              {language}
            </Flex>
            <Flex justifyContent={"space-between"} alignItems="center" width={"100%"}>
              <Box>-</Box>
              <Box ml={2}>
                <Link
                  to={`/description/create/${language}/${field}/${id}?customLink=${
                    customLink ? customLink : ""
                  }`}
                >
                  <Icon as={FcPlus} />
                </Link>
              </Box>
            </Flex>
          </Flex>
        ) : (
          <Flex
            key={language}
            borderBottom={"1px solid"}
            borderColor="gray.200"
            alignItems={"center"}
            py={2}
          >
            <Flex
              color={"white"}
              width={"23px"}
              height="23px"
              backgroundColor={"green.600"}
              rounded={"full"}
              fontSize={"10px"}
              fontWeight="semibold"
              justifyContent="center"
              alignItems={"center"}
              mr={2}
            >
              {language}
            </Flex>
            <Flex justifyContent={"space-between"} alignItems="center" width={"100%"}>
              <Box>{description[0].title} </Box>
              <Box ml={2} display="flex" experimental_spaceX={2}>
                <IconButton
                  icon={<FcEditImage />}
                  aria-label="Update Item"
                  as={Link}
                  to={`/description/update/${language}/${field}/${id}/${
                    description[0].id
                  }?customLink=${customLink ? customLink : ""}`}
                  size="xs"
                />

                {language !== LanguageOptions.TR && (
                  <DeleteItem
                    id={description[0].id}
                    loading={deleteLoading}
                    title={`${description[0].title}`}
                    handleDelete={handleDelete}
                    size="xs"
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};

export default Description;
