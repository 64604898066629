import { Box } from "@chakra-ui/react";
import React from "react";

const PageLayout: React.FC = ({ children }) => {
  return (
    <Box bgColor={"white"} p={6} rounded="md" shadow={"md"}>
      {children}
    </Box>
  );
};

export default PageLayout;
