import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import {
  LanguageOptions,
  SortOrder,
  useDeleteSizeMutation,
  useSizesQuery,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";
import { IoMdReorder } from "react-icons/io";
import Description from "../../components/description/Description";

const All: React.VFC = () => {
  const [deleteMutation, { loading: deleteLoading }] = useDeleteSizeMutation();
  const toast = useToast();
  const { loading, error, data, refetch } = useSizesQuery({
    variables: {
      orderBy: {
        order: SortOrder.asc,
      },
    },
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error />;
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });

      return toast({
        duration: 1500,
        title: "Başarılı",
        status: "success",
        description: "Silme işlemi tamamlandı",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Hata",
        status: "error",
        description: error.message || "Bir hata oluştu",
      });
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Boyutlar
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to="/size/order"
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to="/size/create"
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Başlık</Th>
            <Th>SKU</Th>
            <Th textAlign="center">İşlem</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.sizes.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>

                <Td>
                  <Description
                    field="size"
                    id={c.id}
                    descriptions={c.descriptions}
                    refetch={refetch}
                  />
                </Td>

                <Td>{c.sku}</Td>

                <Td>
                  <Flex justifyContent={"center"}>
                    <Box mx={2}>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/size/update/${c.id}`}
                      />
                    </Box>
                    <Box mx={2}>
                      <DeleteItem
                        id={c.id}
                        loading={deleteLoading}
                        title={`${
                          c.descriptions.find((d) => d.language === LanguageOptions.TR)
                            ?.title
                        }`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </PageLayout>
  );
};

export default All;
