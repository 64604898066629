import { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
  chakra,
  Select,
} from "@chakra-ui/react";
import { FcList } from "react-icons/fc";
import { $enum } from "ts-enum-util";
import { Editor } from "@tinymce/tinymce-react";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";

import PageLayout from "../../components/ui/PageLayout";
import {
  BrandType,
  CreateProductCustomMutationVariables,
  LanguageOptions,
  ProductQuery,
  UpdateProductMutationVariables,
  useCategoriesQuery,
  useCollectionsQuery,
  useColorsQuery,
  useSizesQuery,
} from "../../generated/graphql";
import { createSlug } from "../../utils/slug";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import SkeletonGroup from "../../components/ui/SkeletonGroup";

interface Props {
  handleCreate?: (data: CreateProductCustomMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateProductMutationVariables) => Promise<any>;
  disconnectProductWithCollection?: () => Promise<any>;
  id?: string;
  data?: ProductQuery["product"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({
  handleCreate,
  handleUpdate,
  id,
  data,
  actionType,
  disconnectProductWithCollection,
}) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photos, setPhotos] = useState(data?.photos || []);
  const [brand, setBrand] = useState<BrandType>(data?.brand || BrandType.SINEMIS);
  const [status, setStatus] = useState(data?.status);
  const [price, setPrice] = useState(0);
  const [categoryId, setCategoryId] = useState(data?.categoryId);
  const [collectionId, setCollectionId] = useState(data?.collectionId || "");
  const [sizes, setSizes] = useState<{ label: string; value: string }[]>([]);
  const [colors, setColors] = useState<{ label: string; value: string }[]>([]);
  const [sku, setSku] = useState(data?.sku || "");
  const { data: categoriesData, loading: categoriesLoading } = useCategoriesQuery({
    fetchPolicy: "network-only",
  });

  const { data: collectionsData, loading: collectionsLoading } = useCollectionsQuery({
    fetchPolicy: "network-only",
  });

  const { data: sizesData, loading: sizesLoading } = useSizesQuery({
    fetchPolicy: "network-only",
  });
  const { data: colorsData, loading: colorsLoading } = useColorsQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      const sizesArray = data.sizes.map((t) => ({
        value: t.sizeId,
        label: t.size.descriptions.find((d) => d.language === LanguageOptions.TR)!.title,
      }));
      setSizes(() => [...sizesArray]);
      const colorsArray = data.colors.map((t) => ({
        value: t.colorId,
        label: t.color.descriptions.find((d) => d.language === LanguageOptions.TR)!.title,
      }));
      setColors(() => [...colorsArray]);
    }
  }, [data]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Title is required",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!categoryId) {
      return toast({
        status: "error",
        description: "categoryId is required",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!collectionId && actionType === "CREATE" && brand === BrandType.SINEMIS) {
      return toast({
        status: "error",
        description: "collectionId is required",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!brand) {
      return toast({
        status: "error",
        description: "brand is required",
        title: "Hata",
        duration: 1000,
      });
    }

    if (actionType === "CREATE") {
      await handleCreate!({
        data: {
          descriptions: {
            create: [
              {
                title,
                description,
                slug: createSlug(title),
              },
            ],
          },
          photos: { set: photos },
          brand: brand.toUpperCase() as BrandType,
          category: { connect: { id: categoryId } },
          collection: collectionId ? { connect: { id: collectionId } } : undefined,
          sizes: { createMany: { data: sizes.map((s) => ({ sizeId: s.value })) } },
          price,
          status,
          colors: { createMany: { data: colors.map((s) => ({ colorId: s.value })) } },
          sku,
        },
      });
    }
    if (actionType === "UPDATE") {
      await handleUpdate!({
        where: {
          id: id,
        },
        data: {
          photos: { set: photos },
          brand: { set: brand },
          sizes: { createMany: { data: sizes.map((s) => ({ sizeId: s.value })) } },
          category: { connect: { id: categoryId } },
          collection: collectionId ? { connect: { id: collectionId } } : undefined,
          status: { set: status },
          colors: { createMany: { data: colors.map((s) => ({ colorId: s.value })) } },
          sku: { set: sku },
        },
      });
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Ürün {actionType === "CREATE" ? "Ekle" : "Düzenle"}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton to="/product" as={Link} icon={<FcList />} aria-label="All Items" />
        </Flex>
      </Flex>

      <chakra.form
        onSubmit={handleSubmit}
        display="flex"
        flexDirection={{
          base: "column",
          md: "row",
        }}
        flexWrap="wrap"
      >
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <UploadDropzone isSingle={false} images={photos} setImages={setPhotos} />
          <SimpleGrid
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Başlık</FormLabel>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Başlık"
                />
              </GridItem>
            )}
            <GridItem colSpan={2}>
              <FormLabel>SKU</FormLabel>
              <Input
                value={sku}
                onChange={(e) => setSku(e.target.value)}
                placeholder="SKU"
              />
            </GridItem>

            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Açıklama</FormLabel>
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  initialValue={undefined}
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Açıklama...",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            )}
            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Fiyat</FormLabel>
                <Input
                  value={price}
                  type="number"
                  step={0.1}
                  min={0}
                  onChange={(e) => setPrice(Number(e.target.value))}
                  placeholder="Fiyat"
                />
                <chakra.span fontSize="xs" color="gray.500">
                  Fiyatı TL cinsinden giriniz. Dolar fiyatı otomatik eklenecektir.
                </chakra.span>
              </GridItem>
            )}

            {categoriesLoading ? (
              <GridItem colSpan={2}>
                <SkeletonGroup amount={1} />
              </GridItem>
            ) : (
              <GridItem colSpan={2}>
                <FormLabel>Kategori</FormLabel>
                <ReactSelect
                  options={categoriesData?.categories.map((c) => ({
                    value: c.id,
                    label: `${c.brand}-${
                      c.descriptions.find((l) => l.language === LanguageOptions.TR)?.title
                    } ${
                      c.parentCategoryId
                        ? `- [${
                            c.parentCategory?.descriptions.find(
                              (l) => l.language === LanguageOptions.TR
                            )?.title
                          }]`
                        : ""
                    }`,
                  }))}
                  {...(actionType === "UPDATE" && {
                    ...{
                      value: categoriesData!.categories
                        .map((c) => ({
                          label: `${c.brand}-${
                            c.descriptions.find((l) => l.language === LanguageOptions.TR)
                              ?.title
                          } ${
                            c.parentCategoryId
                              ? `- [${
                                  c.parentCategory?.descriptions.find(
                                    (l) => l.language === LanguageOptions.TR
                                  )?.title
                                }]`
                              : ""
                          }`,
                          value: c.id,
                        }))
                        .filter((c) => c.value === categoryId),
                    },
                  })}
                  onChange={(e) => {
                    setCategoryId(e ? e.value : "");
                  }}
                />
              </GridItem>
            )}

            {collectionsLoading ? (
              <GridItem colSpan={2}>
                <SkeletonGroup amount={1} />
              </GridItem>
            ) : (
              <GridItem colSpan={2}>
                <FormLabel>Koleksiyon</FormLabel>
                <Flex>
                  <Box flex={1}>
                    <ReactSelect
                      options={collectionsData!.collections.map((c) => ({
                        value: c.id,
                        label: `${
                          c.descriptions.find((l) => l.language === LanguageOptions.TR)
                            ?.title
                        }`,
                      }))}
                      {...(actionType === "UPDATE" && {
                        ...{
                          value: collectionsData!.collections
                            .map((c) => ({
                              label: `${
                                c.descriptions.find(
                                  (l) => l.language === LanguageOptions.TR
                                )?.title
                              }`,
                              value: c.id,
                            }))
                            .filter((c) => c.value === collectionId),
                        },
                      })}
                      onChange={(e) => {
                        setCollectionId(e ? e.value : "");
                      }}
                    />
                  </Box>
                  {actionType === "UPDATE" && (
                    <Button
                      colorScheme="red"
                      onClick={disconnectProductWithCollection}
                      ml={4}
                    >
                      Koleksiyondan Çıkart
                    </Button>
                  )}
                </Flex>
              </GridItem>
            )}

            {sizesLoading ? (
              <GridItem colSpan={2}>
                <SkeletonGroup amount={1} />
              </GridItem>
            ) : (
              <GridItem colSpan={2}>
                <FormLabel>Boyutlar</FormLabel>
                <ReactSelect
                  isLoading={sizesLoading}
                  isDisabled={sizesLoading}
                  isClearable
                  {...(actionType === "UPDATE" &&
                    sizesData && {
                      ...{
                        value: sizes,
                      },
                    })}
                  isMulti
                  placeholder="Boyutlar"
                  options={
                    sizesData?.sizes
                      ? sizesData.sizes.length > 0
                        ? sizesData.sizes.map((tag) => ({
                            label: `${
                              tag.descriptions.find(
                                (d) => d.language === LanguageOptions.TR
                              )?.title
                            } - [${tag.sku}]`,
                            value: tag.id,
                          }))
                        : []
                      : []
                  }
                  onChange={(e: any) => {
                    setSizes(e);
                  }}
                />
              </GridItem>
            )}

            {colorsLoading ? (
              <GridItem colSpan={2}>
                <SkeletonGroup amount={1} />
              </GridItem>
            ) : (
              <GridItem colSpan={2}>
                <FormLabel>Renkler</FormLabel>
                <ReactSelect
                  isLoading={colorsLoading}
                  isDisabled={colorsLoading}
                  isClearable
                  {...(actionType === "UPDATE" &&
                    colorsData && {
                      ...{
                        value: colors,
                      },
                    })}
                  isMulti
                  placeholder="Renkler"
                  options={
                    colorsData?.colors
                      ? colorsData?.colors.length > 0
                        ? colorsData?.colors.map((tag) => ({
                            label: `${
                              tag.descriptions.find(
                                (d) => d.language === LanguageOptions.TR
                              )?.title
                            } - [${tag.code}]`,
                            value: tag.id,
                            color: `${tag.code}`,
                          }))
                        : []
                      : []
                  }
                  onChange={(e: any) => {
                    setColors(e);
                  }}
                />
              </GridItem>
            )}

            <GridItem colSpan={2}>
              <FormLabel>Marka</FormLabel>
              <Select
                value={brand}
                onChange={(e) => setBrand(e.target.value as BrandType)}
              >
                <option value={undefined}></option>
                {$enum(BrandType).map((b, i) => (
                  <option key={i} value={b}>
                    {b}
                  </option>
                ))}
              </Select>
            </GridItem>

            <GridItem
              colSpan={{
                base: 2,
                md: 1,
              }}
            >
              <FormLabel>Durum</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={status ? "green" : "gray"}
                    onClick={() => setStatus(true)}
                  >
                    Aktif
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!status ? "red" : "gray"}
                    onClick={() => setStatus(false)}
                  >
                    Pasif
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
        </Box>

        <Box width="100%">
          <Button colorScheme={"green"} type="submit" mt={6}>
            Kaydet
          </Button>
        </Box>
      </chakra.form>
    </PageLayout>
  );
};

export default Form;
