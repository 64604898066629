import { useState } from "react";
import { useDebounce } from "use-debounce";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Flex,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Image,
  useToast,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  SortOrder,
  QueryMode,
  useDeleteProductMutation,
  useProductsQuery,
  LanguageOptions,
  useUpdateProductMutation,
  BrandType,
} from "../../generated/graphql";
import { FcRefresh, FcPlus, FcEditImage, FcAddRow } from "react-icons/fc";
import DeleteItem from "../../components/delete/DeleteItem";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import Pagination from "../../components/pagination/Pagination";
import Description from "../../components/description/Description";
import StatusBox from "../../components/statusbox/StatusBox";
import DiscountModal from "../../components/DiscountModal";
import Price from "../../components/price/Price";
import { IoMdReorder } from "react-icons/io";
import { MdOutlinePriceChange } from "react-icons/md";

const All = () => {
  const toast = useToast();
  const [deleteMutation, { loading: deleteLoading }] = useDeleteProductMutation();
  const [recordsPerPage, setRecordsPerPage] = useState(150);
  const [brands, setBrands] = useState([BrandType.BOLDPIECES, BrandType.SINEMIS]);
  const [page, setPage] = useState(1);
  const skip = (page - 1) * recordsPerPage;
  const [searchWord, setSearchWord] = useState("");
  const [searchValue] = useDebounce(searchWord, 1000);
  const { loading, error, data, refetch } = useProductsQuery({
    variables: {
      orderBy: {
        createdAt: SortOrder.desc,
      },
      take: recordsPerPage,
      skip,
      where: {
        brand: {
          equals: BrandType.BOLDPIECES,
        },
        ...(searchValue && {
          descriptions: {
            some: { title: { contains: searchValue, mode: QueryMode.insensitive } },
          },
        }),
      },
    },
    fetchPolicy: "network-only",
  });
  const [updateProduct, { loading: updateLoading }] = useUpdateProductMutation();
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  const totalPages = data ? Math.ceil(data?.products.count / recordsPerPage) : 0;

  const handleDelete = async (id: string) => {
    try {
      await deleteMutation({
        variables: {
          where: {
            id,
          },
        },
      });

      return toast({
        duration: 1500,
        title: "Başarılı",
        status: "success",
        description: "Silme işlemi tamamlandı",
        onCloseComplete: async () => {
          return refetch();
        },
      });
    } catch (error: any) {
      return toast({
        duration: 1500,
        title: "Hata",
        status: "error",
        description: error.message || "Bir hata oluştu",
      });
    }
  };

  const switchItemStatus = async (id: string, status: boolean) => {
    try {
      await updateProduct({
        variables: { where: { id }, data: { status: { set: !status } } },
      });
      refetch();
    } catch (error: any) {
      return toast({
        title: "Hata",
        description: error.message || "Bir hata oluştu",
        isClosable: true,
        duration: 2000,
        status: "error",
      });
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Ürünler
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to="/product/bold/order"
            as={Link}
            icon={<IoMdReorder fontSize="24px" />}
            aria-label="Order"
            onClick={() => refetch()}
          />
          <IconButton
            icon={<FcRefresh />}
            aria-label="Refetch Query"
            onClick={() => refetch()}
          />
          <IconButton
            to="/product/create"
            as={Link}
            icon={<FcPlus />}
            aria-label="Add Item"
            onClick={() => refetch()}
          />
        </Flex>
      </Flex>
      <SimpleGrid mb={3} columns={3} gap={4}>
        <FormControl>
          <FormLabel>Arama</FormLabel>
          <Input
            type="text"
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            placeholder="Aranacak kelime..."
          />
        </FormControl>
      </SimpleGrid>
      <Table>
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th>Görsel</Th>
            <Th>Başlık</Th>
            <Th>Kategori</Th>
            <Th>Marka</Th>
            <Th textAlign="center">Fiyat</Th>
            <Th width="50px" textAlign="center">
              Durum
            </Th>
            <Th width="150px" textAlign="center">
              İşlem
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.products.items.map((c, i) => {
            return (
              <Tr key={i}>
                <Td>{i + 1}</Td>
                <Td w="10%">
                  <Image
                    maxW="80px"
                    src={
                      c.photos[0]
                        ? `${process.env.REACT_APP_CDN}/${c.photos[0]}`
                        : "/noimg.png"
                    }
                    alt="Product"
                  />
                </Td>
                <Td>
                  <Description
                    field="product"
                    id={c.id}
                    descriptions={c.descriptions}
                    refetch={refetch}
                  />
                </Td>
                <Td>
                  {c.category.descriptions[0].title}({c.category.brand})
                </Td>
                <Td>{c.brand}</Td>
                <Td>
                  <Price productId={c.id} prices={c.prices} refetch={refetch} />
                </Td>
                <Td w="50px" textAlign="center">
                  <StatusBox
                    status={c.status}
                    onClick={() => switchItemStatus(c.id, c.status)}
                    loading={updateLoading}
                    progressProps={{ size: "10px" }}
                  />
                </Td>
                <Td>
                  <Flex wrap={"wrap"} gap={2} justifyContent={"center"}>
                    <Box>
                      <DiscountModal
                        refetch={refetch}
                        discountAmount={c.discountAmount}
                        discountType={c.discountType}
                        name={
                          c.descriptions.find((l) => l.language === LanguageOptions.TR)!
                            .title || ""
                        }
                        productId={c.id}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        aria-label="Price History"
                        icon={<MdOutlinePriceChange />}
                        as={Link}
                        to={`/priceHistory/${c.id}`}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        aria-label="Edit"
                        icon={<FcEditImage />}
                        as={Link}
                        to={`/product/update/${c.id}`}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        aria-label="Size"
                        icon={<FcAddRow />}
                        as={Link}
                        to={`/product/size/${c.id}`}
                      />
                    </Box>
                    <Box>
                      <DeleteItem
                        id={c.id}
                        loading={deleteLoading}
                        title={`${
                          c.descriptions.find((d) => d.language === LanguageOptions.TR)
                            ?.title
                        }`}
                        handleDelete={handleDelete}
                      />
                    </Box>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Pagination
        page={page}
        setPage={setPage}
        totalPage={totalPages}
        recordsPerPage={recordsPerPage}
        setRecordsPerPage={setRecordsPerPage}
      />
    </PageLayout>
  );
};

export default All;
