import { Fragment, useState } from "react";
import {
  Box,
  Flex,
  SimpleGrid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Heading,
  useToast,
  InputLeftElement,
  InputRightElement,
  Button,
} from "@chakra-ui/react";
import {
  OrderStatus,
  useOrderQuery,
  useUpdateOrderMutation,
} from "../../generated/graphql";
import dayjs from "dayjs";
import Loading from "../../components/loading/Loading";
import Error from "../../components/error/Error";
import PageLayout from "../../components/ui/PageLayout";
import { MdLocalShipping } from "react-icons/md";

interface Props {
  id: string;
}

interface ShippingNumberInputProps {
  shippingNumberData: string;
  isShipped: boolean;
  orderId: string;
  refetch(): any;
}

function ShippingNumberInput({
  shippingNumberData,
  orderId,
  refetch,
  isShipped,
}: ShippingNumberInputProps) {
  const [updateOrder, { loading: updateLoading }] = useUpdateOrderMutation();
  const toast = useToast();
  const [shippingNumber, setShippingNumber] = useState(shippingNumberData || "");
  const handleSendShippingNumber = async () => {
    if (!shippingNumber) {
      return toast({
        title: "Hata",
        description: "Takip numarasını boş bırakmayınız",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    try {
      await updateOrder({
        variables: {
          where: { id: orderId },
          data: {
            shippingNumber: { set: shippingNumber },
            isShipped: { set: true },
            orderStatus: { set: OrderStatus.SHIPPED },
          },
        },
      });

      return toast({
        title: "Başarılı",
        description: "Kaydedildi",
        status: "success",
        duration: 750,
        isClosable: true,
        onCloseComplete: async () => {
          await refetch();
        },
      });
    } catch (error: any) {
      return toast({
        title: "Hata",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  const handleMarkAsDelivered = async () => {
    try {
      await updateOrder({
        variables: {
          where: { id: orderId },
          data: {
            orderStatus: { set: OrderStatus.COMPLETED },
          },
        },
      });

      return toast({
        title: "Başarılı",
        description: "Kaydedildi",
        status: "success",
        duration: 750,
        isClosable: true,
        onCloseComplete: async () => {
          await refetch();
        },
      });
    } catch (error: any) {
      return toast({
        title: "Hata",
        description: error.message,
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  };

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" children={<MdLocalShipping />} />
      <Input
        pr="12.5rem"
        type="text"
        placeholder="Takip numarası"
        value={shippingNumber}
        onChange={(e) => setShippingNumber(e.target.value)}
      />
      <InputRightElement width="12rem">
        <Button
          h="1.75rem"
          size="sm"
          colorScheme="green"
          mr="1rem"
          onClick={handleSendShippingNumber}
          isDisabled={updateLoading || (shippingNumberData ? true : false)}
        >
          Kaydet
        </Button>
        {isShipped && (
          <Button
            h="1.75rem"
            size="sm"
            colorScheme="green"
            isDisabled={!isShipped}
            onClick={handleMarkAsDelivered}
          >
            Teslim Et
          </Button>
        )}
      </InputRightElement>
    </InputGroup>
  );
}

const Detail: React.VFC<Props> = ({ id }) => {
  const { loading, error, data, refetch } = useOrderQuery({
    variables: {
      where: {
        id,
      },
    },
    // fetchPolicy: "network-only",
  });
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }

  return (
    <Fragment>
      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Sipariş Detayı
          </Box>
        </Flex>
        <SimpleGrid columns={2} gap={6}>
          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Sipariş Tarihi" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${dayjs(data?.order!.createdAt).format("DD MMM YYYY HH:mm")}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Müşteri" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.order?.customer.name} ${data?.order?.customer.surname}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Müşteri E-Posta" />
              <Input fontSize={"sm"} disabled value={`${data?.order?.customer.email}`} />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Müşteri Telefon" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.order?.customer.phoneNumber}`}
              />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Sipariş Durumu" />
              <Input fontSize={"sm"} disabled value={`${data?.order?.orderStatus}`} />
            </InputGroup>
          </GridItem>

          <GridItem
            colSpan={{
              base: 3,
              md: 1,
            }}
          >
            <InputGroup>
              <InputLeftAddon width={"120px"} fontSize="xs" children="Kupon Kullanımı" />
              <Input
                fontSize={"sm"}
                disabled
                value={`${data?.order?.discountCoupon ? "Evet" : "Hayır"}`}
              />
            </InputGroup>
          </GridItem>
        </SimpleGrid>
      </PageLayout>

      <Box my={4} />

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Ürünler
          </Box>
        </Flex>

        <Box overflow="auto">
          <Table>
            <Thead>
              <Tr>
                <Th>Ürün</Th>
                <Th>Beden</Th>
                <Th>Birim Fiyat</Th>
                <Th>Miktar</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data?.order?.products.map((order) => (
                <Tr key={order.id}>
                  <Td>{order.product.descriptions[0]?.title}</Td>
                  <Td>
                    {order.selectedSize && order.selectedSize.size.descriptions[0]?.title}
                  </Td>
                  <Td>{order.price}</Td>
                  <Td>{order.quantity}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </PageLayout>

      <Box my={4} />

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Kargo & Fatura
          </Box>
        </Flex>

        <SimpleGrid columns={2} spacing={8}>
          <Box>
            <Heading size="sm" mb={2}>
              Fatura Bilgileri
            </Heading>
            <Box overflow="auto">
              <Table variant="unstyled">
                <Tbody>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Ad Soyad
                    </Th>
                    <Td>{`${data?.order?.paymentFirstName} ${data?.order?.paymentLastName}`}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      E-Posta
                    </Th>
                    <Td>{data?.order?.paymentEmail}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Telefon
                    </Th>
                    <Td>{data?.order?.paymentPhoneNumber}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Adres
                    </Th>
                    <Td>{`${data?.order?.paymentAddress} ${data?.order?.paymentPostalCode} ${data?.order?.paymentCity}/${data?.order?.paymentCountry}`}</Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>

          <Box>
            <Heading size="sm" mb={2}>
              Teslimat Bilgileri
            </Heading>
            <Box overflow="auto">
              <Table variant="unstyled">
                <Tbody>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Ad Soyad
                    </Th>
                    <Td>{`${data?.order?.shippingFirstName} ${data?.order?.shippingLastName}`}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      E-Posta
                    </Th>
                    <Td>{data?.order?.shippingEmail}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Telefon
                    </Th>
                    <Td>{data?.order?.shippingPhoneNumber}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Adres
                    </Th>
                    <Td>{`${data?.order?.shippingAddress} ${data?.order?.shippingPostalCode} ${data?.order?.shippingCity}/${data?.order?.shippingCountry}`}</Td>
                  </Tr>
                  <Tr borderBottom="1px solid #ccc">
                    <Th w="20%" bgColor="gray.400">
                      Kargo No {data?.order?.isShipped}
                    </Th>
                    <Td>
                      <ShippingNumberInput
                        orderId={data?.order!.id!}
                        isShipped={data?.order!.isShipped!}
                        refetch={refetch}
                        shippingNumberData={data?.order?.shippingNumber || ""}
                      />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </SimpleGrid>
      </PageLayout>

      <Box my={4} />

      <PageLayout>
        <Flex
          mb={6}
          justifyContent={"space-between"}
          alignItems="center"
          bgColor={"gray.100"}
          padding={2}
          rounded="md"
        >
          <Box fontSize={"18px"} fontWeight="bold">
            Ödeme Bilgileri
          </Box>
        </Flex>

        <Box overflow="auto">
          <Table variant="unstyled">
            <Tbody>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Tarih
                </Th>
                <Td>
                  {dayjs(data?.order?.payment?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Durum
                </Th>
                <Td>{data?.order?.payment?.status}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Hata Kodu
                </Th>
                <Td>{data?.order?.payment?.errorCode}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Hata Mesajı
                </Th>
                <Td>{data?.order?.payment?.errorMessage}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Toplam Ücret
                </Th>
                <Td>{data?.order?.payment?.price}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Ödenen Ücret
                </Th>
                <Td>{data?.order?.payment?.paidPrice}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Ödeme ID
                </Th>
                <Td>{data?.order?.payment?.id}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Taksit
                </Th>
                <Td>{data?.order?.payment?.installment}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Auth Code
                </Th>
                <Td>{data?.order?.payment?.authCode}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico BIN Number
                </Th>
                <Td>{data?.order?.payment?.binNumber}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Kart son 4 hane
                </Th>
                <Td>{data?.order?.payment?.lastFourDigits}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Komisyon Miktarı
                </Th>
                <Td>{data?.order?.payment?.iyziCommissionFee}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Komisyon Oranı
                </Th>
                <Td>{data?.order?.payment?.iyziCommissionRateAmount}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Pazar Komisyon Oranı
                </Th>
                <Td>{data?.order?.payment?.merchantCommissionRate}</Td>
              </Tr>
              <Tr borderBottom="1px solid #ccc">
                <Th w="20%" bgColor="gray.400">
                  Iyzico Pazar Komisyon Miktarı
                </Th>
                <Td>{data?.order?.payment?.merchantCommissionRateAmount}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </PageLayout>
    </Fragment>
  );
};

export default Detail;
