import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  SimpleGrid,
  GridItem,
  FormLabel,
  Input,
  Flex,
  IconButton,
  Link,
} from "@chakra-ui/react";
import {
  useDescriptionLazyQuery,
  useUpdateDescriptionMutation,
  useCreateDescriptionMutation,
  LanguageOptions,
} from "../../generated/graphql";
import { Editor } from "@tinymce/tinymce-react";
import PageLayout from "../../components/ui/PageLayout";
import { FcList } from "react-icons/fc";
import { createSlug } from "../../utils/slug";

const Form = () => {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const field = params.field as "page";
  const id = params["*"];
  const fieldId = params.fieldId;
  const customLink = searchParams.get("customLink");
  const action = params.action;
  const language = params.language as LanguageOptions;
  const navigation = useNavigate();
  const [createDescription, { loading }] = useCreateDescriptionMutation();
  const [updateDescription, { loading: updateLoading }] = useUpdateDescriptionMutation();
  const [getDescription, { data }] = useDescriptionLazyQuery();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [headline, setHeadline] = useState("");
  // const [buttonText, setButtonText] = useState("");
  // const [buttonUrl, setButtonUrl] = useState("");

  useEffect(() => {
    if (id && action === "update") {
      getDescription({
        variables: {
          where: {
            id,
          },
        },
      });
    }
  }, [id, action, getDescription]);
  useEffect(() => {
    if (data?.description) {
      setTitle(data.description.title);
      setDescription(data.description.description || "");
      setHeadline(data.description.headline || "");
      // setButtonText(data.description.buttonText || "");
      // setButtonUrl(data.description.buttonUrl || "");
    }
  }, [data?.description]);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (action === "create") {
      await createDescription({
        variables: {
          data: {
            title,
            description,
            headline,
            slug: createSlug(title),
            // buttonText,
            // buttonUrl,
            [field]: { connect: { id: fieldId } },
            language,
          },
        },
      });
      return navigation(`/${customLink ? customLink : field}`);
    }
    if (action === "update") {
      await updateDescription({
        variables: {
          where: {
            id,
          },
          data: {
            title: {
              set: title,
            },
            description: {
              set: description,
            },
            slug: {
              set: createSlug(title),
            },
            /* buttonText: {
              set: buttonText,
            },
            buttonUrl: {
              set: buttonUrl,
            }, */
            headline: {
              set: headline,
            },
          },
        },
      });
      return navigation(`/${customLink ? customLink : field}`);
    }
    return navigation(`/${customLink ? customLink : field}`);
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Dil {params.action === "create" ? "Ekle" : "Düzenle"}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/currency`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>

      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid columns={2} gap={6}>
            <GridItem colSpan={2}>
              <FormLabel>Başlık</FormLabel>
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Başlık"
              />
            </GridItem>

            {/* <GridItem colSpan={2}>
              <FormLabel>Headline</FormLabel>
              <Input
                value={headline}
                onChange={(e) => setHeadline(e.target.value)}
                placeholder="Headline"
              />
            </GridItem> */}

            <GridItem colSpan={2}>
              <FormLabel>Açıklama</FormLabel>
              <Editor
                initialValue={data?.description?.description || undefined}
                apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                outputFormat="html"
                onEditorChange={(e) => setDescription(e)}
                init={{
                  skin: "snow",
                  icons: "thin",
                  placeholder: "İçerik açıklaması...",
                  height: 300,
                  menubar: false,
                  min_height: 200,
                  max_height: 500,

                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                    "removeformat | code",
                }}
              />
            </GridItem>
          </SimpleGrid>
          <Button
            colorScheme="green"
            type="submit"
            mt={6}
            isDisabled={loading || updateLoading}
            isLoading={loading || updateLoading}
          >
            Kaydet
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
