import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { Editor } from "@tinymce/tinymce-react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

import {
  CollectionQuery,
  CreateCollectionMutationVariables,
  UpdateCollectionMutationVariables,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import UploadDropzone from "../../components/dropzone/UploadDropzone";
import { createSlug } from "../../utils/slug";

interface Props {
  handleCreate?: (data: CreateCollectionMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateCollectionMutationVariables) => Promise<any>;
  id?: string;
  data?: CollectionQuery["collection"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [photos, setPhotos] = useState(data?.photos || []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Title is required",
        title: "Hata",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title,
                  description,
                  slug: createSlug(title),
                },
              ],
            },
            photos: { set: photos },
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdate!({
          where: {
            id: id,
          },
          data: {
            photos: { set: photos },
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          Koleksiyon {actionType === "CREATE" ? "Ekle" : "Düzenle"}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to={`/category`}
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <UploadDropzone isSingle={false} images={photos} setImages={setPhotos} />
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Başlık</FormLabel>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Başlık"
                />
              </GridItem>
            )}

            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Açıklama</FormLabel>
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  initialValue={undefined}
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Açıklama...",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            )}
          </SimpleGrid>
          <Button colorScheme="green" type="submit" mt={6}>
            Kaydet
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
