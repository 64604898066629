import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  IconButton,
  Input,
  Flex,
} from "@chakra-ui/react";
import { CgAdd, CgCheck } from "react-icons/cg";
import { useState } from "react";

const PriceModal = ({
  title,
  onSubmit,
  id,
  defaultPrice,
  priceId,
}: {
  title: string;
  id: string;
  defaultPrice?: number;
  priceId?: string;
  onSubmit: (id: string, price: number, priceId?: string) => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [price, setPrice] = useState(defaultPrice || 0);

  return (
    <>
      <IconButton onClick={onOpen} aria-label="add" size="sm" icon={<CgAdd />} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Input
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(Number(e.target.value))}
                mx={2}
              />
              <IconButton
                onClick={() => {
                  if (priceId) {
                    onSubmit(id, price, priceId);
                    setPrice(0);
                    onClose();
                  } else {
                    onSubmit(id, price);
                    setPrice(0);
                    onClose();
                  }
                }}
                aria-label="check"
                size="md"
                icon={<CgCheck />}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
            <IconButton
              onClick={() => {
                onSubmit(id, price);
                setPrice(0);
                onClose();
              }}
              aria-label="check"
              size="md"
              icon={<CgCheck />}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PriceModal;
