import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateSizeMutationVariables,
  useCreateSizeMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createMutation] = useCreateSizeMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreate = async (data: CreateSizeMutationVariables) => {
    try {
      await createMutation({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Başarılı",
        description: "Boyut eklendi",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/size");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Hata",
        description: error.message || "Bir hata oluştu",
        duration: 1000,
      });
    }
  };
  return <Form handleCreate={handleCreate} actionType="CREATE" />;
};

export default Create;
