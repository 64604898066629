import React from "react";
import {
  UpdateColorMutationVariables,
  useColorQuery,
  useUpdateColorMutation,
} from "../../generated/graphql";
import { useToast } from "@chakra-ui/react";
import Loading from "../../components/loading/Loading";
import { Navigate, useNavigate } from "react-router-dom";
import Form from "./Form";

interface Props {
  id: string;
}

const Update: React.VFC<Props> = ({ id }) => {
  const [updateMutation] = useUpdateColorMutation();
  const navigation = useNavigate();
  const toast = useToast();

  const { loading, error, data } = useColorQuery({
    variables: {
      where: {
        id,
      },
    },
    fetchPolicy: "network-only",
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Navigate to={"/size"} replace />;
  }

  const handleUpdate = async (data: UpdateColorMutationVariables) => {
    try {
      await updateMutation({
        variables: {
          ...data,
        },
      });

      return toast({
        status: "success",
        title: "Başarılı",
        description: "Renk güncellendi",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/color");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Hata",
        description: error.message || "Bir hata oluştu",
        duration: 1000,
      });
    }
  };

  return (
    <Form actionType="UPDATE" data={data?.color} id={id} handleUpdate={handleUpdate} />
  );
};

export default Update;
