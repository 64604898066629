import { useState } from "react";
import {
  Box,
  SimpleGrid,
  GridItem,
  Input,
  FormLabel,
  Button,
  useToast,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import ReactSelect from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import PageLayout from "../../components/ui/PageLayout";
import { Link } from "react-router-dom";

import {
  CreateDiscountCouponMutationVariables,
  DiscountCouponQuery,
  UpdateDiscountCouponMutationVariables,
  useCustomersQuery,
} from "../../generated/graphql";
import { FcList } from "react-icons/fc";
import { createSlug } from "../../utils/slug";
import dayjs from "dayjs";

interface Props {
  handleCreate?: (data: CreateDiscountCouponMutationVariables) => Promise<any>;
  handleUpdate?: (data: UpdateDiscountCouponMutationVariables) => Promise<any>;
  id?: string;
  data?: DiscountCouponQuery["discountCoupon"];
  actionType: "CREATE" | "UPDATE";
}

const Form: React.FC<Props> = ({ handleCreate, handleUpdate, id, data, actionType }) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [code, setCode] = useState(data?.code || "");
  const [amount, setAmount] = useState(data?.amount || 0);
  const [validUntil, setValidUntil] = useState(
    dayjs(data?.validUntil).format("YYYY-MM-DD") || ""
  );
  const [customerId, setCustomerId] = useState(data?.customerId);
  const [status, setStatus] = useState(data?.status && true);
  const [isPercentage, setIsPercentage] = useState(data?.isPercentage && true);

  const { data: usersData } = useCustomersQuery();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!title && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Başlık gereklidir",
        title: "Hata",
        duration: 1000,
      });
    }

    if (!code && actionType === "CREATE") {
      return toast({
        status: "error",
        description: "Kod gereklidir",
        title: "Hata",
        duration: 1000,
      });
    }

    try {
      if (actionType === "CREATE") {
        await handleCreate!({
          data: {
            descriptions: {
              create: [
                {
                  title,
                  description,
                  slug: createSlug(title),
                },
              ],
            },
            code,
            amount,
            validUntil,
            isPercentage: isPercentage ? true : false,
            status: status ? true : false,
            ...(customerId && { customer: { connect: { id: customerId } } }),
          },
        });
      }
      if (actionType === "UPDATE") {
        await handleUpdate!({
          where: {
            id: id,
          },
          data: {
            code: { set: code },
            amount: { set: amount },
            validUntil: { set: validUntil },
            isPercentage: { set: isPercentage ? true : false },
            status: { set: status ? true : false },
            ...(customerId
              ? { customer: { connect: { id: customerId } } }
              : { customer: { disconnect: true } }),
          },
        });
      }
    } catch (error) {
      console.log("hata");
    }
  };

  return (
    <PageLayout>
      <Flex
        mb={6}
        justifyContent={"space-between"}
        alignItems="center"
        bgColor={"gray.100"}
        padding={2}
        rounded="md"
      >
        <Box fontSize={"18px"} fontWeight="bold">
          İndirim Kuponu {actionType === "CREATE" ? "Ekle" : "Düzenle"}
        </Box>
        <Flex experimental_spaceX={4}>
          <IconButton
            to="/discountcoupon"
            as={Link}
            icon={<FcList />}
            aria-label="All Items"
          />
        </Flex>
      </Flex>
      <form onSubmit={handleSubmit}>
        <Box
          width={{
            base: "100%",
            md: "50%",
          }}
        >
          <SimpleGrid
            mt={4}
            columns={2}
            gap={{
              base: 6,
              md: 6,
            }}
          >
            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Başlık</FormLabel>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Başlık"
                />
              </GridItem>
            )}

            {actionType === "CREATE" && (
              <GridItem colSpan={2}>
                <FormLabel>Açıklama</FormLabel>
                <Editor
                  apiKey="4vyocpjnhdhjtx3k08o1cv89jqhz5d6ae7910ubihafl7yes"
                  initialValue={undefined}
                  outputFormat="html"
                  onEditorChange={(e) => setDescription(e)}
                  init={{
                    skin: "snow",
                    icons: "thin",
                    placeholder: "Açıklama...",
                    height: 300,
                    menubar: false,
                    min_height: 200,
                    max_height: 500,

                    plugins: [
                      "advlist autolink lists link image charmap print preview anchor",
                      "searchreplace visualblocks code fullscreen",
                      "insertdatetime media table paste code wordcount",
                    ],
                    toolbar:
                      "undo redo | formatselect | " +
                      "bold italic backcolor forecolor | bullist numlist outdent indent | " +
                      "removeformat | code",
                  }}
                />
              </GridItem>
            )}

            <GridItem colSpan={2}>
              <FormLabel>Kod</FormLabel>
              <Input value={code} onChange={(e) => setCode(e.target.value)} />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>İndirim Oranı</FormLabel>
              <Input value={amount} onChange={(e) => setAmount(Number(e.target.value))} />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Geçerlilik Tarihi</FormLabel>
              <Input
                type="date"
                value={validUntil}
                onChange={(e) => setValidUntil(e.target.value)}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Üyeye Özel İndirim</FormLabel>
              {usersData && (
                <ReactSelect
                  placeholder="Müşteri Seçimi"
                  options={usersData!.customers.items.map((user) => ({
                    value: user.id,
                    label: `${user.name} ${user.surname} - ${user.email}`,
                  }))}
                  {...(actionType === "UPDATE" && {
                    ...{
                      value: usersData
                        ?.customers!.items.map((user) => ({
                          label: `${user.name} ${user.surname} - ${user.email}`,
                          value: user.id,
                        }))
                        .filter((c) => c.value === customerId),
                    },
                  })}
                  isClearable
                  onChange={(e, p) => {
                    if (p.action === "clear") {
                      setCustomerId(undefined);
                    }
                    setCustomerId(e && e.value ? e.value : undefined);
                  }}
                />
              )}
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Yüzdelik İndirim</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={isPercentage ? "green" : "gray"}
                    onClick={() => setIsPercentage(true)}
                  >
                    Evet
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!isPercentage ? "red" : "gray"}
                    onClick={() => setIsPercentage(false)}
                  >
                    Hayır
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>

            <GridItem colSpan={2}>
              <FormLabel>Durum</FormLabel>
              <SimpleGrid columns={2} gap={6}>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={status ? "green" : "gray"}
                    onClick={() => setStatus(true)}
                  >
                    Aktif
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    width={"full"}
                    colorScheme={!status ? "red" : "gray"}
                    onClick={() => setStatus(false)}
                  >
                    Pasif
                  </Button>
                </GridItem>
              </SimpleGrid>
            </GridItem>
          </SimpleGrid>
          <Button colorScheme="green" type="submit" mt={6}>
            Kaydet
          </Button>
        </Box>
      </form>
    </PageLayout>
  );
};

export default Form;
