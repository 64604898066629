import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  FormLabel,
  GridItem,
  IconButton,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { FcEditImage } from "react-icons/fc";
import { Link } from "react-router-dom";
import {
  ProductsQuery,
  useUpdatePriceMutation,
  usePriceQuery,
  PriceQuery,
} from "../../generated/graphql";

interface Props {
  productId: string;
  prices: ProductsQuery["products"]["items"][0]["prices"];
  refetch: any;
}

const Price: React.VFC<Props> = ({ productId, prices, refetch }) => {
  const [updatePrice, { loading: updateLoading }] = useUpdatePriceMutation();
  const [price, setPrice] = useState<string>(
    prices.find((p) => p.currency.code === "USD")?.price.toString() || "0"
  );
  const [showPrice, setShowPrice] = useState(true);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    await updatePrice({
      variables: {
        productId: productId!,
        price: Number(Number(price).toFixed(2)),
      },
    });
    await refetch();
    setShowPrice(true);
  };

  return (
    <Box>
      {prices.map((pr) => {
        const priceData = prices.filter((d) => d.currency.code === pr.currency.code);
        return (
          <Flex
            key={pr.id}
            borderBottom={"1px solid"}
            borderColor="gray.200"
            alignItems={"center"}
            py={2}
          >
            <Badge colorScheme="blue" mr={1}>
              {pr.currency.code}
            </Badge>
            <Flex justifyContent="space-between" alignItems="center" width={"100%"}>
              {pr.currency.code !== "USD" && <Box>{priceData[0].price} </Box>}
              {pr.currency.code === "USD" && showPrice ? (
                <Box>{priceData[0].price} </Box>
              ) : (
                pr.currency.code === "USD" && (
                  <form onSubmit={handleSubmit}>
                    <Box>
                      <Flex>
                        <Input
                          type="number"
                          step={0.1}
                          min={0}
                          size="xs"
                          value={price}
                          onChange={(e) => setPrice(e.target.value)}
                          placeholder="Ürün fiyatı"
                        />
                        <Button
                          colorScheme="green"
                          type="submit"
                          size="xs"
                          isDisabled={updateLoading}
                          isLoading={updateLoading}
                        >
                          Kaydet
                        </Button>
                      </Flex>
                    </Box>
                  </form>
                )
              )}
              <Box ml={2} display="flex" experimental_spaceX={2}>
                {pr.currency.code === "USD" && (
                  <IconButton
                    icon={<FcEditImage />}
                    aria-label="Update Item"
                    onClick={() => setShowPrice(!showPrice)}
                    size="xs"
                  />
                )}
              </Box>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};

export default Price;
