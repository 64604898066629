import Form from "./Form";
import { useToast } from "@chakra-ui/react";
import {
  CreateCustomerMutationVariables,
  useCreateCustomerMutation,
} from "../../generated/graphql";
import { useNavigate } from "react-router-dom";

const Create = () => {
  const [createUser] = useCreateCustomerMutation();
  const navigation = useNavigate();
  const toast = useToast();
  const handleCreateUser = async (data: CreateCustomerMutationVariables) => {
    try {
      await createUser({
        variables: {
          data: data.data,
        },
      });

      return toast({
        status: "success",
        title: "Başarılı",
        description: "Müşteri oluşturuldu",
        duration: 1000,
        onCloseComplete: () => {
          return navigation("/customer");
        },
      });
    } catch (error: any) {
      return toast({
        status: "error",
        title: "Hata",
        description: error.message || "Bir hata oluştu",
        duration: 1000,
      });
    }
  };
  return <Form handleCreateUser={handleCreateUser} actionType="CREATE" />;
};

export default Create;
